.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
}

.desktopBg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-image: url('/static/video/bg-1080.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.mobileBg {
  width: 100%;
  height: 60vh;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(
      349.54deg,
      rgba(11, 29, 38, 0) 35.78%,
      rgba(11, 29, 38, 0.9) 88.34%
    ),
    linear-gradient(180deg, rgba(11, 29, 38, 0) 18.61%, #0b1d26), url('/static/video/bg-1080.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.gradients {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(349.54deg, rgba(11, 29, 38, 0) 35.78%, rgba(11, 29, 38, 0.9) 88.34%),
    linear-gradient(173.6deg, rgba(11, 29, 38, 0) 18.61%, #0b1d26 106.52%);
  z-index: -1;
}

.inner {
  width: 100%;
  padding: 0 48px;

  @media screen and (min-width: 768px) {
    max-width: 600px;
  }

  @media screen and (min-width: 992px) {
    max-width: 880px;
    padding: 0;
  }

  h1 {
    font-size: 24px;

    // font-weight: 700;
    font-weight: inherit;
    line-height: 1.2em;
    padding: 32px 0;

    @media screen and (min-width: 768px) {
      font-size: 48px;
    }

    @media screen and (min-width: 992px) {
      font-size: 64px;
    }

    .titleLine {
      display: block;
      text-transform: uppercase;
      overflow-y: hidden;

      span {
        position: relative;
        display: inline-block;
      }
    }
  }
}

.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';

  font-size: 13px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 4px;
  }
}

.desc {
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  line-height: 1.4em;
  position: relative;
  padding-left: 44px;

  @media screen and (min-width: 768px) {
    padding-left: 78px;
  }

  @media screen and (min-width: 992px) {
    font-size: 18px;
  }

  .line {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.7em;
    display: inline-block;
    content: '';
    width: 27px;
    min-width: 27px;

    .animatedLine {
      top: 0;
      left: 0;
      content: '';
      display: block;
      height: 1px;
      background-color: #ffffff;

      @media screen and (min-width: 768px) {
        height: 2px;
      }
    }

    @media screen and (min-width: 768px) {
      font-size: 13px;
      width: 54px;
      min-width: 54px;
      margin-right: 24px;
    }
  }
}

.scrollDown {
  margin-top: 48px;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  &:after {
    margin-left: 8px;
    content: '';
    width: 12px;
    height: 18px;
    background-size: 12px 18px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDE2TDE0LjU5IDE0LjU5TDkgMjAuMTdWMEg3VjIwLjE3TDEuNDIgMTQuNThMMCAxNkw4IDI0TDE2IDE2WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: arrow-bounce;
    animation-timing-function: ease-out;
  }

  @keyframes arrow-bounce {
    0% {
      transform: translateY(-15%);
      opacity: 0.3;
    }
    50% {
      transform: translateY(15%);
      opacity: 1;
    }
    100% {
      transform: translateY(-15%);
      opacity: 0.3;
    }
  }
}
