.wrapper {
  position: relative;
}

.rtp {
  border-radius: 8px !important;
}

.geoMap {
}

.point {
  cursor: pointer;
  outline: none;
}

.tooltip {
  position: absolute;
  background: linear-gradient(0deg, rgba(14, 16, 17, 0.7), rgba(14, 16, 17, 0.7));
  padding: 16px;
  backdrop-filter: blur(32px);
  border-radius: 16px;
  z-index: 9999;
  width: 100%;
  max-width: 196px;

  .title {
    color: #bca169;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 4px;
    font-size: 1em;
  }

  .products {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      a {
        margin-top: 24px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-decoration: none;
        color: #ffffff;

        img,
        span {
          transition: 0.2s ease;
        }

        img {
          opacity: 0.7;
        }

        span {
          opacity: 0.8;
        }

        &:hover {
          img,
          span {
            opacity: 1;
          }
        }
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }
}

.circle1,
.circle2,
.circle3 {
  opacity: 0;
  fill: #f2b586;
}

.circle3 {
  animation: circlePin ease 0.5s 0.7s forwards, circlePin2 ease 3s 4s infinite;
}

.circle2 {
  animation: circlePin ease 0.5s 1s forwards, circlePin2 ease 3s 4.3s infinite;
}

.circle1 {
  animation: circlePin ease 0.5s 1.3s forwards, circlePin2 ease 3s 4.6s infinite;
}

.title {
  color: #bca169;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2px;
  letter-spacing: 0.5px;
}

@keyframes circlePin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes circlePin2 {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes defaultDot {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.9;
  }
}
