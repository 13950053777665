.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 96px;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0;
  }
}

.left {
  width: 100%;
  position: relative;

  @media screen and (min-width: 992px) {
    width: 50%;
    padding-left: 112px;
  }
}

.right {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 0 46px;

  @media screen and (min-width: 992px) {
    flex-direction: column;
    width: 50%;
    padding: 0 32px;
  }

  .item {
    width: 50%;
    margin-top: 38px;

    &:not(:first-child) {
      @media screen and (min-width: 992px) {
        margin-top: 38px;
      }
    }

    p {
      margin-top: 16px;
      font-size: 10px;
      padding-right: 16px;

      @media screen and (min-width: 480px) {
        margin-left: 44px;
      }

      @media screen and (min-width: 992px) {
        margin-left: 78px;
        font-size: 16px;
        padding-right: 48px;
      }
    }

    @media screen and (min-width: 992px) {
      width: 100%;
      margin-top: 0;
    }
  }
}

.num {
  font-size: 96px;
  position: absolute;
  top: -0.55em;
  left: 0.2em;
  z-index: -1;
  opacity: 0.1;

  @media screen and (min-width: 960px) {
    left: 18px;
    font-size: 180px;
  }

  @media screen and (min-width: 1440px) {
    left: -6px;
  }
}

.title {
  font-size: 20px;
  // font-weight: 700;
  padding-left: 48px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    margin-top: 0.5em;
    font-size: 36px;
    max-width: 75%;
  }
}

.desc {
  line-height: 1.8em;
  margin-top: 32px;
  font-size: 12px;
  padding-left: 48px;
  padding-right: 32px;

  @media screen and (min-width: 992px) {
    padding-right: 48px;
    padding-left: 0;
    font-size: 16px;
    max-width: 90%;
  }
}

.readMore {
  margin-top: 1em;
  font-size: 13px;
  padding-left: 48px;

  &.visibleXs {
    padding-left: 46px;
    margin-top: 32px;
  }

  a {
    color: #bca169;
    text-decoration: none;

    span {
      display: inline-block;
    }

    .arrow {
      transition: 0.2s;
      margin-left: 8px;
      margin-top: -2px;
      vertical-align: middle;
      height: 18px;
      width: 18px;
      content: '';
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1NCAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2IDMuODE0N2UtMDZMMzIuODI3NSAzLjE3MjVMNDUuMzgyNSAxNS43NUwwIDE1Ljc1TDAgMjAuMjVMNDUuMzgyNSAyMC4yNUwzMi44MDUgMzIuODA1TDM2IDM2TDU0IDE4TDM2IDMuODE0N2UtMDZaIiBmaWxsPSIjQkNBMTY5Ii8+Cjwvc3ZnPgo=');
    }

    &:hover {
      .arrow {
        transform: translateX(4px) !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 2em;
    font-size: 16px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 0;
  }
}

.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';
  padding-left: 48px;
  font-size: 13px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    font-size: 16px;
    letter-spacing: 4px;
  }
}

.subHeader {
  composes: headline from '@/components/ui/Headline/headline.module.scss';
  font-size: 10px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    font-size: 16px;
    letter-spacing: 4px;
  }
}

.hiddenXs {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
}

.visibleXs {
  display: block;
  @media screen and (min-width: 992px) {
    display: none;
  }
}
