.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 96px;
  flex: 1 0 auto;
  justify-content: space-around;

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
}

.partners {
  margin-top: 64px;
  margin-bottom: 64px;

  .body {
    padding: 0 32px;
    overflow-x: scroll;
    display: block;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  img {
    user-select: none;
    max-height: 48px;
    &:not(:first-child) {
      margin-left: 32px;

      @media screen and (min-width: 768px) {
        margin-left: 78px;
        max-height: 64px;
      }
    }

    &:last-child {
      padding-right: 32px;
    }
  }
}

.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';
  padding-left: 48px;
  font-size: 13px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    padding-left: 24px;
    font-size: 16px;
    letter-spacing: 4px;
  }
}

.slide {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  img {
    height: 100%;
    max-height: 100px;
    margin: 0 auto;
  }
}

.dots {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .dot {
    &:not(:first-child) {
      margin-left: 32px;
    }

    background-color: rgba(188, 161, 105, 0);
    outline: none;
    border: 2px solid #bca169;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    transition: background-color 0.2s;

    @media screen and (min-width: 768px) {
      width: 16px;
      height: 16px;
    }

    &:hover {
      background-color: rgba(188, 161, 105, 0.3);
    }

    &.dotActive {
      background-color: rgba(188, 161, 105, 1);
    }
  }
}

.slider {
  max-width: 80%;
  margin: auto;
}
