.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 96px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0;
  }
}

.right {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 992px) {
    align-items: flex-end;
  }
}

.head {
  position: relative;

  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.bottom {
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.slider {
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 50%;
    padding-right: 96px;
    order: 1;
    align-self: flex-start;
    margin-top: -360px;
  }

  .slide {
    aspect-ratio: 1080 / 720;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      max-width: 90%;
      max-height: 100%;
      height: auto;
      width: 90%;
      // max-height: 192px;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        max-height: 256px;
      }

      @media screen and (min-width: 992px) {
        max-height: inherit;
      }
    }
  }

  .dots {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .dot {
      &:not(:first-child) {
        margin-left: 32px;
      }

      background-color: rgba(188, 161, 105, 0);
      outline: none;
      border: 2px solid #bca169;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      transition: background-color 0.2s;

      @media screen and (min-width: 768px) {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: rgba(188, 161, 105, 0.3);
      }

      &.dotActive {
        background-color: rgba(188, 161, 105, 1);
      }
    }
  }
}

.left {
  width: 100%;
  min-height: 320px;
  background-position: top left;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: contain;
  padding: 0 32px;
  min-height: 500px;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.num {
  font-size: 96px;
  position: absolute;
  top: -0.55em;
  left: 0.2em;
  z-index: -1;
  opacity: 0.1;

  @media screen and (min-width: 992px) {
    left: -0.65em;
    font-size: 180px;
    margin-left: 20px;
  }
}

.title {
  font-size: 20px;

  // font-weight: 700;
  padding-left: 48px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    margin-top: 0.5em;
    font-size: 36px;
    max-width: 75%;
  }
}

.desc {
  padding-right: 48px;
  line-height: 1.8em;
  margin-top: 32px;
  font-size: 12px;
  padding-left: 48px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    font-size: 16px;
    max-width: 90%;
  }
}

.readMore {
  margin-top: 1em;
  font-size: 13px;
  padding-left: 48px;

  a {
    color: #bca169;
    text-decoration: none;

    span {
      display: inline-block;
    }

    .arrow {
      transition: 0.2s;
      margin-left: 8px;
      margin-top: -2px;
      vertical-align: middle;
      height: 18px;
      width: 18px;
      content: '';
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1NCAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2IDMuODE0N2UtMDZMMzIuODI3NSAzLjE3MjVMNDUuMzgyNSAxNS43NUwwIDE1Ljc1TDAgMjAuMjVMNDUuMzgyNSAyMC4yNUwzMi44MDUgMzIuODA1TDM2IDM2TDU0IDE4TDM2IDMuODE0N2UtMDZaIiBmaWxsPSIjQkNBMTY5Ii8+Cjwvc3ZnPgo=');
    }

    &:hover {
      .arrow {
        transform: translateX(4px) !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 2em;
    font-size: 16px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 0;
  }
}

.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';
  padding-left: 48px;
  font-size: 13px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
    font-size: 16px;
    letter-spacing: 4px;
  }
}
