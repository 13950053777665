.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 96px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.left {
  display: flex;
  flex-direction: column;
  padding-left: 48px;

  @media screen and (min-width: 992px) {
    padding-left: 112px;
  }
}

.map {
  width: 100%;
  margin-top: 32px;
  padding-right: 48px;

  svg {
    width: 100%;
    overflow: visible;
    max-height: 380px;
    @media screen and (min-width: 960px) {
      max-height: 430px;
    }
  }

  @media screen and (min-width: 768px) {
    padding-right: 96px;
  }

  @media screen and (min-width: 992px) {
    width: 60%;
    order: 1;
    align-self: flex-end;
    margin-top: -360px;
    padding-right: 128px;
  }
}

.num {
  font-size: 96px;
  position: absolute;
  top: -0.55em;
  left: 0.2em;
  z-index: -1;
  opacity: 0.1;

  @media screen and (min-width: 960px) {
    left: 18px;
    font-size: 180px;
  }

  @media screen and (min-width: 1440px) {
    left: -6px;
  }
}

.title {
  font-size: 20px;


  // font-weight: 700;

  @media screen and (min-width: 992px) {
    margin-top: 0.5em;
    width: 40%;
    font-size: 36px;
  }
}

.desc {
  line-height: 1.8em;
  padding-right: 48px;
  margin-top: 32px;
  font-size: 12px;

  @media screen and (min-width: 992px) {
    width: 40%;
    font-size: 16px;
  }
}

.readMore {
  margin-top: 1em;
  font-size: 13px;
  a {
    color: #bca169;
    text-decoration: none;

    span {
      display: inline-block;
    }

    .arrow {
      transition: 0.2s;
      margin-left: 8px;
      margin-top: -2px;
      vertical-align: middle;
      height: 18px;
      width: 18px;
      content: '';
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTQiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1NCAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2IDMuODE0N2UtMDZMMzIuODI3NSAzLjE3MjVMNDUuMzgyNSAxNS43NUwwIDE1Ljc1TDAgMjAuMjVMNDUuMzgyNSAyMC4yNUwzMi44MDUgMzIuODA1TDM2IDM2TDU0IDE4TDM2IDMuODE0N2UtMDZaIiBmaWxsPSIjQkNBMTY5Ii8+Cjwvc3ZnPgo=');
    }

    &:hover {
      .arrow {
        transform: translateX(4px) !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 2em;
    font-size: 16px;
  }
}

.header {
  composes: headline from '@/components/ui/Headline/headline.module.scss';

  font-size: 13px;
  letter-spacing: 2px;

  @media screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 4px;
  }
}
